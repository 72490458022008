import React, { useRef, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { FaPhoneAlt, FaPhoneSlash } from 'react-icons/fa';
import log from 'loglevel';
import './VideoCall.css';

log.setLevel('debug');

const SIGNALING_SERVER_URL = 'https://www.ma16688.com:8765';

const VideoCall = () => {
    const localVideoRef = useRef(null);
    const remoteVideoRef = useRef(null);
    const socket = useRef(null);
    const peerConnectionRef = useRef(null);
    const pendingCandidates = useRef([]);
    const [inCall, setInCall] = useState(false);
    const [permissionStatus, setPermissionStatus] = useState("尚未檢查");
    const [errorLogs, setErrorLogs] = useState([]);
    const callRingRef = useRef(new Audio('/lineapp_ring_16k.mp3'));
    const endCallSoundRef = useRef(new Audio('/lineapp_end_16k.wav'));
    const adminSocketIdRef = useRef(null);

    const logError = (message) => {
        setErrorLogs((prevLogs) => [...prevLogs, message]);
        log.error(message);
    };

    useEffect(() => {
        socket.current = io(SIGNALING_SERVER_URL);

        socket.current.on("connect", () => {
            log.info("已連接到訊號伺服器。");
            socket.current.emit("register", { isAdmin: false });
        });

        socket.current.on("answer", async (data) => {
            if (peerConnectionRef.current) {
                try {
                    const remoteDesc = new RTCSessionDescription(data.answer);
                    await peerConnectionRef.current.setRemoteDescription(remoteDesc);
                    log.info("已設定遠端描述（answer）。");
                    callRingRef.current.pause();
                    callRingRef.current.currentTime = 0;

                    while (pendingCandidates.current.length > 0) {
                        const candidate = pendingCandidates.current.shift();
                        await peerConnectionRef.current.addIceCandidate(candidate);
                        log.info("已添加暫存的 ICE 候選者。");
                    }
                } catch (error) {
                    logError("設定遠端描述（answer）時出錯: " + error.message);
                    endCall();
                }
            }
        });

        socket.current.on("candidate", async (data) => {
            const candidate = new RTCIceCandidate(data.candidate);
            if (peerConnectionRef.current && peerConnectionRef.current.remoteDescription) {
                try {
                    await peerConnectionRef.current.addIceCandidate(candidate);
                    log.info("已添加來自管理員的 ICE 候選者。");
                } catch (error) {
                    logError("添加 ICE 候選者時出錯: " + error.message);
                }
            } else {
                pendingCandidates.current.push(candidate);
                log.info("儲存 ICE 候選者，等待 peerConnection 準備就緒。");
            }
        });

        socket.current.on("hangup", () => {
            log.info("管理員已掛斷通話。");
            endCall();
        });

        socket.current.on("disconnect", () => {
            log.info("已從訊號伺服器斷開。");
            endCall();
        });

        socket.current.on("adminConnected", (data) => {
            adminSocketIdRef.current = data.adminSocketId;
            log.info("已獲取管理員的 Socket ID:", data.adminSocketId);
        });

        return () => {
            if (socket.current) {
                socket.current.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        const localVideoElement = document.querySelector('.local-video-draggable');
        let startX, startY, initialX = 0, initialY = 0;

        const handleTouchStart = (e) => {
            startX = e.touches[0].clientX - initialX;
            startY = e.touches[0].clientY - initialY;
        };

        const handleTouchMove = (e) => {
            e.preventDefault();
            initialX = e.touches[0].clientX - startX;
            initialY = e.touches[0].clientY - startY;
            localVideoElement.style.transform = `translate(${initialX}px, ${initialY}px)`;
        };

        localVideoElement.addEventListener('touchstart', handleTouchStart);
        localVideoElement.addEventListener('touchmove', handleTouchMove);

        return () => {
            localVideoElement.removeEventListener('touchstart', handleTouchStart);
            localVideoElement.removeEventListener('touchmove', handleTouchMove);
        };
    }, []);

    const createPeerConnection = () => {
        const peerConnectionConfig = {
            iceServers: [
                { urls: 'stun:stun.l.google.com:19302' },
                {
                    urls: 'turn:13.114.88.90:3478',
                    username: 'testuser',
                    credential: 'testpassword',
                }
            ]
        };

        const pc = new RTCPeerConnection(peerConnectionConfig);

        pc.onicecandidate = (event) => {
            if (event.candidate) {
                socket.current.emit("candidate", { candidate: event.candidate.toJSON(), target: adminSocketIdRef.current });
                log.info("已發送 ICE 候選者:", event.candidate);
            }
        };

        pc.ontrack = (event) => {
            if (remoteVideoRef.current) {
                remoteVideoRef.current.srcObject = event.streams[0];
                log.info("已接收到遠端影片流。");
            }
        };

        pc.onconnectionstatechange = () => {
            if (pc.connectionState === "disconnected" || pc.connectionState === "failed") {
                log.info("連接中斷，結束通話。");
                endCall();
            }
        };

        return pc;
    };

    const startCall = async () => {
        try {
            setPermissionStatus("正在請求權限...");
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            setPermissionStatus("已授權");

            if (localVideoRef.current) {
                localVideoRef.current.srcObject = stream;
                log.info("本地視訊流已設置到 localVideoRef。");
            } else {
                logError("無法找到 localVideoRef，無法設置本地視訊流。");
                return;
            }

            const pc = createPeerConnection();
            peerConnectionRef.current = pc;
            setInCall(true);

            stream.getTracks().forEach(track => pc.addTrack(track, stream));

            const offer = await pc.createOffer();
            await pc.setLocalDescription(offer);
            socket.current.emit("offer", { offer: pc.localDescription });
            log.info("已發送 offer 給管理員。");

            callRingRef.current.loop = true;
            callRingRef.current.play().catch(error => {
                log.warn("播放鈴聲失敗，原因:", error);
            });

        } catch (error) {
            logError("無法存取媒體設備: " + error.message);
            setInCall(false);
            setPermissionStatus("未授權 - 請確認瀏覽器權限");
        }
    };

    const endCall = () => {
        if (peerConnectionRef.current) {
            peerConnectionRef.current.close();
            peerConnectionRef.current = null;
        }

        if (localVideoRef.current && localVideoRef.current.srcObject) {
            localVideoRef.current.srcObject.getTracks().forEach(track => track.stop());
            localVideoRef.current.srcObject = null;
        }
        if (remoteVideoRef.current && remoteVideoRef.current.srcObject) {
            remoteVideoRef.current.srcObject.getTracks().forEach(track => track.stop());
            remoteVideoRef.current.srcObject = null;
        }

        setInCall(false);
        log.info("通話已結束。");

        if (callRingRef.current) {
            callRingRef.current.pause();
            callRingRef.current.currentTime = 0;
        }

        endCallSoundRef.current.play().catch(error => {
            log.warn("播放掛斷音效失敗，原因:", error);
        });

        if (socket.current) {
            socket.current.emit("clientHangup");
        }
    };

    return (
        <div className="video-call-container">
            <div className="permission-status" style={{ position: 'absolute', top: '10px', left: '10px', color: 'white', background: 'rgba(0,0,0,0.5)', padding: '5px', borderRadius: '5px' }}>
                設備授權狀態：{permissionStatus}
            </div>
            <div className="remote-video-container">
                <video ref={remoteVideoRef} autoPlay playsInline className="remote-video" />
                <div className="local-video-draggable">
                    <video ref={localVideoRef} autoPlay muted playsInline className="local-video" />
                </div>
            </div>
            <div className="error-logs">
                {errorLogs.map((log, index) => (
                    <p key={index} style={{ color: 'red' }}>{log}</p>
                ))}
            </div>
            <div className="controls">
                {inCall ? (
                    <div className="control-button hangup" onClick={endCall}>
                        <FaPhoneSlash size={24} />
                        <span>結束通話</span>
                    </div>
                ) : (
                    <div className="control-button call" onClick={startCall}>
                        <FaPhoneAlt size={24} />
                        <span>開始通話</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VideoCall;
